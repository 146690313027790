import { imageNotFoundStyles } from './image-not-found-styles'

// Packages
import React, { FC } from 'react'
import { LogoCarros } from '@gjc/classi-react-commons/Icons/LogoCarros'

const ImageNotFound: FC = () => {
    const style = imageNotFoundStyles()

    return (
        <div className={style.container}>
            <LogoCarros />
        </div>
    )
}

export default ImageNotFound
