import {
    AdResponse,
    BrandValueResponse,
    FilterBody,
    FilterResponse,
    FilterLocationsResponse,
    SendMessageForm,
    AnnouncerResponse,
    UserData,
    Motive,
    ReportAdData,
    FavoriteResponse,
    SitemapData,
} from './types'
import useApi from './useApi'

// Packages
import { useQuery, UseQueryResult } from '@tanstack/react-query'

// Models
import { GetCarsResponse } from '../models/filter/filter.model'

// Components
import { PopularBrandCard } from '../components/SearchedBrands'

interface GetAnnouncementByIdParams {
    id: string
}

interface GetAnnouncementSimilarParams {
    id: string
}

export interface GetAnnoucementsSimilarResponse {
    descricao?: string
    id: number
    imagem: string
    titulo: string
    subtitulo: string
    valor: string
    prioridade: string
    url: string
    isFeirao?: boolean
}

type GetAnnouncementByIdResponse = AdResponse

type GetFiltersSelectsResponse = FilterResponse

type GetAnnoucementsListPayload = FilterBody

type GetAnnoucementsListResponse = GetCarsResponse

type GetFilterLocationResponse = FilterLocationsResponse

interface GetFilterLocationProps {
    search: string
    isEnabled?: boolean
}

interface GetBrandsAndModelsByStringParams {
    search: string
    category: string
    isEnabled?: boolean
}

interface GetBrandsAndModelsByStringResponse {
    descricao: string
    options: [
        {
            agrupamento: string
            descricao: string
            quantidade: number
            valor: string
        }
    ]
}

interface VehiclesApiParams {
    token?: string
}

const VehiclesApi = (props: VehiclesApiParams) => {
    const { get, post } = useApi({ token: props?.token })
    const getPopularBrands = ({ category }: { category: string | number }): Promise<BrandValueResponse[]> =>
        get({ path: `/vitrine-veiculos/marcas-mais-buscadas?quantidade=12&categoria=${category}` })
    const getAnnouncementById = ({ id }: GetAnnouncementByIdParams): Promise<GetAnnouncementByIdResponse> =>
        get({ path: `/vitrine-veiculos/${id}` })
    const getFiltersSelects = (): Promise<GetFiltersSelectsResponse> => post({ path: '/vitrine-veiculos/filtro' })
    const getAnnouncementsList = (filter: GetAnnoucementsListPayload): Promise<GetAnnoucementsListResponse> =>
        post({
            path: '/vitrine-veiculos/consultaVitrineVeiculos',
            data: filter,
        })

    const getAnnouncementSimilar = ({ id }: GetAnnouncementSimilarParams): Promise<GetAnnoucementsSimilarResponse> =>
        get({ path: `vitrine-veiculos/consultarAnunciosSimilares?idAnuncio=${id}&qtdAnuncios=6` })

    const getProfile = (): Promise<UserData> => get({ path: '/perfil/consultaDados' })

    const getBrandsAndModelsByString = ({
        search,
        category,
    }: GetBrandsAndModelsByStringParams): Promise<GetBrandsAndModelsByStringResponse[]> =>
        get({
            path: `/vitrine-veiculos/consulta-marca-modelo-versao?search=${search}&categoria=${category}`,
        })
    const getFilterLocations = (search: string): Promise<GetFilterLocationResponse[]> =>
        get({ path: `vitrine-veiculos/localizacao?search=${search}&secao=VEICULOS` })

    const postSendMessage = (form: SendMessageForm) => post({ path: '/vitrine-imoveis/sendMessage', data: form })

    const getAnnouncerById = (id: string): Promise<AnnouncerResponse> =>
        get({ path: `/vitrine-veiculos/anunciantes/${id}?id=${id}` })

    const postUpdateProfile = async (perfilInput: Partial<UserData>) =>
        await post({ path: '/perfil/atualizaDados', data: perfilInput })

    const getAnnouncementIsFavorite = (id: string) => get({ path: `/vitrine-veiculos/favorito/${id}` })

    const getSetFavoriteAnnouncement = (id: string) =>
        get({ path: `/vitrine-veiculos/favoritarAnuncio?idAnuncio=${id}` })

    const getSetUnfavoriteAnnouncement = (id: string) =>
        get({ path: `/vitrine-veiculos/desfavoritarAnuncio?idAnuncio=${id}` })

    const getReportMotives = (): Promise<Motive[]> => get({ path: '/vitrine-veiculos/findMotivosDenuncia' })

    const postSendReport = (form: ReportAdData) => post({ path: '/vitrine-veiculos/sendDenuncia', data: form })

    const getFavorites = () => get({ path: '/vitrine-veiculos/consulta-favoritos' })

    const getSitemap = ({ page }: SitemapData) =>
        get({ path: `/site-maps?tipo-anuncio=VEICULO${page ? '&page=' + page : ''}` })

    return {
        getPopularBrands,
        getAnnouncementById,
        getFiltersSelects,
        getAnnouncementsList,
        getBrandsAndModelsByString,
        getFilterLocations,
        getAnnouncementSimilar,
        getProfile,
        postSendMessage,
        getAnnouncerById,
        postUpdateProfile,
        getAnnouncementIsFavorite,
        getSetFavoriteAnnouncement,
        getSetUnfavoriteAnnouncement,
        getReportMotives,
        postSendReport,
        getFavorites,
        getSitemap,
    }
}

const VehiclesQueries = {
    useGetPopularBrandsQuery: ({
        category,
        isEnabled,
    }: {
        category: string
        isEnabled: boolean
    }): UseQueryResult<PopularBrandCard[]> => {
        return useQuery({
            queryKey: ['getPopularBrands', category],
            queryFn: () => {
                return VehiclesApi({ token: '' }).getPopularBrands({ category })
            },
            enabled: isEnabled,
            staleTime: 60 * 1000,
        })
    },
    useGetAnnouncementsListQuery: (filter: GetAnnoucementsListPayload): UseQueryResult<GetCarsResponse> =>
        useQuery({
            queryKey: ['getAnnouncementsList'],
            queryFn: () => VehiclesApi({ token: '' }).getAnnouncementsList(filter),
        }),

    useGetEmphasisAnnouncementsQuery: (filter: GetAnnoucementsListPayload): UseQueryResult<GetCarsResponse> =>
        useQuery({
            queryKey: ['useGetEmphasis'],
            queryFn: () => VehiclesApi({ token: '' }).getAnnouncementsList(filter),
        }),

    useGetFilterSelectQuery: (): UseQueryResult<GetFiltersSelectsResponse> =>
        useQuery({
            queryKey: ['getFiltersSelects'],
            queryFn: () => VehiclesApi({ token: '' }).getFiltersSelects(),
            staleTime: Infinity,
        }),
    useGetAnnouncementByIdQuery: ({ id }: { id: string }) => {
        return useQuery({
            queryKey: ['getAnnouncementById'],
            queryFn: () => VehiclesApi({ token: '' }).getAnnouncementById({ id }),
            staleTime: 60 * 1000,
        })
    },
    useGetBrandsAndModelsQuery: ({
        search,
        category,
        isEnabled,
    }: GetBrandsAndModelsByStringParams): UseQueryResult<GetBrandsAndModelsByStringResponse[]> =>
        useQuery({
            queryKey: ['getBrandsAndModelsByString', search],
            queryFn: () => VehiclesApi({ token: '' }).getBrandsAndModelsByString({ search, category }),
            enabled: isEnabled,
        }),
    useGetFilterLocationsQuery: ({
        search,
        isEnabled,
    }: GetFilterLocationProps): UseQueryResult<GetFilterLocationResponse[]> =>
        useQuery({
            queryKey: ['getFilterLocations', search],
            queryFn: () => VehiclesApi({ token: '' }).getFilterLocations(search),
            enabled: isEnabled,
            staleTime: 60 * 1000,
        }),
    useGetAnnouncerById: (id: string) =>
        useQuery({
            queryKey: ['getAnnouncerById', id],
            queryFn: () => VehiclesApi({ token: '' }).getAnnouncerById(id),
        }),
    useGetProfileQuery: (options: VehiclesApiParams): UseQueryResult<UserData> =>
        useQuery({
            queryKey: ['getProfile', options.token],
            queryFn: () => VehiclesApi(options).getProfile(),
            staleTime: Infinity,
        }),
    useGetAnnouncementIsFavorite: (id: string, options: VehiclesApiParams): UseQueryResult<boolean> =>
        useQuery({
            queryKey: ['getAnnouncementIsFavorite', id],
            queryFn: () => VehiclesApi(options).getAnnouncementIsFavorite(id),
        }),

    useGetReportMotives: (): UseQueryResult<Motive[]> =>
        useQuery({
            queryKey: ['getReportMotives'],
            queryFn: () => VehiclesApi({ token: '' }).getReportMotives(),
            staleTime: Infinity,
        }),

    useGetFavorites: (options: VehiclesApiParams): UseQueryResult<FavoriteResponse[]> =>
        useQuery({
            queryKey: ['getFavorites'],
            queryFn: () => VehiclesApi(options).getFavorites(),
        }),
}

export const {
    useGetPopularBrandsQuery,
    useGetAnnouncementsListQuery,
    useGetAnnouncementByIdQuery,
    useGetFilterSelectQuery,
    useGetBrandsAndModelsQuery,
    useGetFilterLocationsQuery,
    useGetAnnouncerById,
    useGetProfileQuery,
    useGetAnnouncementIsFavorite,
    useGetReportMotives,
    useGetFavorites,
    useGetEmphasisAnnouncementsQuery,
} = VehiclesQueries

export const vehiclesEndpoints = VehiclesApi
