// Packages
import DigitalMarketPlaceBadge from '@gjc/classi-react-commons/core/Badge/DigitalMarketPlaceBadge/DigitalMarketPlaceBadge'
import React from 'react'

// Api
import { GetAnnoucementsSimilarResponse } from '../../api/vehiclesApi'

// Models
import { AnnouncementCard } from '../../models/announcement.model'

// eslint-disable-next-line @typescript-eslint/naming-convention
export function validatePriority(announcement: AnnouncementCard | GetAnnoucementsSimilarResponse) {
    const { isFeirao, prioridade } = announcement

    if (isFeirao) {
        return <DigitalMarketPlaceBadge />
    }

    if (prioridade === 'ALTA') {
        return (
            <div
                style={{
                    border: '1px solid #CD181E',
                    backgroundColor: '#CD181E',
                    color: '#fff',
                    padding: 8,
                    fontWeight: 600,
                    fontSize: 12,
                    textTransform: 'uppercase',
                }}
            >
                Super Destaque
            </div>
        )
    }

    if (prioridade === 'MEDIA') {
        return (
            <div
                style={{
                    border: '1px solid',
                    backgroundColor: '#fff',
                    color: '#CD181E',
                    padding: 8,
                    fontWeight: 600,
                    fontSize: 12,
                    textTransform: 'uppercase',
                }}
            >
                Destaque
            </div>
        )
    }
}
