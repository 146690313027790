// Packages
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const imageNotFoundStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.main,
        },
    })
)

export { imageNotFoundStyles }
