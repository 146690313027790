// Packages
import { makeStyles } from '@material-ui/core/styles'

const commonsStyle = {
    '&:hover': {
        stroke: '#f78484',
        fill: '#f78484',
        cursor: 'pointer',

        '&& path': {
            stroke: '#f78484',
            fill: '#f78484 !important',
        },
    },
}

export const favoriteStyles = makeStyles({
    contained: { ...commonsStyle },
    empty: {
        ...commonsStyle,
        '&& svg path': {
            stroke: '#FFFFFF',
            strokeWidth: 2,
        },
    },
    emptyInsideAnnouncement: {
        ...commonsStyle,
        '&& svg path': {
            stroke: '#979797 !important',
            strokeWidth: '1',
        },
    },
    insideAnnouncement: {
        marginTop: '5px',
        marginLeft: '15px',
    },
})
