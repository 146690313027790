/* eslint-disable @typescript-eslint/no-explicit-any */

// Packages
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

interface HttpProps {
    get: any
    post: any
    put: any
    del: any
}

interface ApiProps {
    get: <T>(config: GetParams) => Promise<T>
    post: <T>(config: PostParams) => Promise<T>
    put: <T>(config: PutParams) => Promise<T>
    del: <T>(config: GetParams) => Promise<T>
}

interface GetParams {
    path: string
    params?: AxiosResponse
    config?: AxiosRequestConfig
}

interface PostParams {
    path: string
    params?: AxiosResponse
    config?: AxiosRequestConfig
    data?: any
}

type PutParams = PostParams

type DelParams = GetParams

const throwError = (error: unknown) => {
    throw error
}

export const useHttp = (baseUrl: string, headers = {}): HttpProps => {
    const http = (): AxiosInstance => {
        return axios.create({
            baseURL: baseUrl,
            headers,
        })
    }

    const get = ({ path, params, config }: GetParams) =>
        http()
            .get(path, { params, ...config })
            .then(resp => resp.data)
            .catch(throwError)
    const post = ({ path, data, params, config }: PostParams) =>
        http()
            .post(path, data, { params, ...config })
            .then(resp => resp.data)
            .catch(throwError)
    const put = ({ path, data, params, config }: PutParams) =>
        http()
            .put(path, data, { params, ...config })
            .then(resp => resp.data)
            .catch(throwError)
    const del = ({ path, params, config }: DelParams) =>
        http()
            .delete(path, { params, ...config })
            .then(resp => resp.data)
            .catch(throwError)

    return {
        get,
        post,
        put,
        del,
    }
}

interface UseApiParams {
    token?: string
}

const useApi = (props: UseApiParams): ApiProps => {
    const { token = '' } = props

    const baseUrl = process.env.NEXT_PUBLIC_API_URL as string

    const headers = {
        Authorization: token ? 'Bearer ' + token : '',
    }

    return useHttp(baseUrl, headers)
}

export default useApi
