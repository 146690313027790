// Packages
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Gpt, GptConfig } from 'react-gpt-ads'
import { GptSizeType } from 'react-gpt-ads/dist/types'

interface AdvertisingProps {
    name: string
    adUnit: string
    type: 'capa' | 'busca'
    size: GptSizeType
    index?: number
}

const advertisingStyles = makeStyles(() => ({
    root: {
        marginTop: '20px',
        marginBottom: '20px',
    },
}))

const Advertising = ({ name, adUnit, type, size }: AdvertisingProps) => {
    const [isReceivedAd, setIsReceivedAd] = useState(false)
    const classes = advertisingStyles()

    const verifyEmptyDiv = () => {
        const element = document.getElementById(name)

        return Boolean(element?.children[0].children.length)
    }

    return (
        <>
            <GptConfig
                enableSingleRequest
                collapseEmptyDivs
                eventSlotOnload={() => {
                    if (verifyEmptyDiv()) {
                        setIsReceivedAd(true)
                    }
                }}
            />
            <div className={`${isReceivedAd ? classes.root : ''}`}>
                {isReceivedAd && <span style={{ fontSize: 9 }}>Publicidade</span>}
                <Gpt
                    name={name}
                    adUnit={adUnit}
                    target={[
                        ['tipo', type],
                        ['editoria', 'carros'],
                        ['ambiente', 'QA'],
                        ['id', name],
                    ]}
                    size={size}
                />
            </div>
        </>
    )
}

export default Advertising
