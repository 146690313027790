// Packages
import React from 'react'
import { FavoriteContainedEmpty } from '@gjc/classi-react-commons/Icons/FavoriteOutlined'
import { FavoriteContained } from '@gjc/classi-react-commons/Icons/FavoriteFilled'
import { useKeycloak } from '@react-keycloak/nextjs'
import { sendAnalytics } from '@gjc/classi-react-commons/core/analytics'
import { AnalyticsEvent } from '@gjc/classi-react-commons/types/Analytics'

// Styles
import { favoriteStyles } from './FavoriteIcon.styles'

// Api
import { useGetAnnouncementIsFavorite, vehiclesEndpoints } from '../../api/vehiclesApi'

interface Props {
    id: string
    isInsideAnnouncement?: boolean
}

const FavoriteIcon = ({ id, isInsideAnnouncement }: Props) => {
    const { keycloak } = useKeycloak()

    const favoriteClasses = favoriteStyles()
    const options = {
        token: keycloak?.token,
    }

    const { data: isFavorite, refetch } = useGetAnnouncementIsFavorite(id, options)

    const toggleFavorite = () => {
        if (!keycloak?.token) {
            keycloak?.login()
        }

        if (isFavorite) {
            sendAnalytics(AnalyticsEvent.RemoveFavorite, { id })

            vehiclesEndpoints(options)
                .getSetUnfavoriteAnnouncement(id)
                .then(() => {
                    refetch()
                })
        } else {
            sendAnalytics(AnalyticsEvent.AddFavorite, { id })

            vehiclesEndpoints(options)
                .getSetFavoriteAnnouncement(id)
                .then(() => {
                    refetch()
                })
        }
    }

    return isFavorite ? (
        <div
            className={`${favoriteClasses.contained} ${isInsideAnnouncement ? favoriteClasses.insideAnnouncement : ''}`}
            onClick={toggleFavorite}
        >
            <FavoriteContained width={isInsideAnnouncement ? 24 : 21} height={isInsideAnnouncement ? 24 : 21} />
        </div>
    ) : (
        <div
            className={`${
                isInsideAnnouncement
                    ? `${favoriteClasses.emptyInsideAnnouncement} ${favoriteClasses.insideAnnouncement}`
                    : favoriteClasses.empty
            }`}
            onClick={toggleFavorite}
        >
            <FavoriteContainedEmpty width={isInsideAnnouncement ? 24 : 21} height={isInsideAnnouncement ? 24 : 21} />
        </div>
    )
}

export default FavoriteIcon
